@import 'colors.css';

.appTitle {
  float: left;
  margin-right: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.siteLayoutSider {
  background-color: var(--background-color);
}

.siteLayoutContent {
  min-height: 280px;
  padding: 24px;
  background: var(--background-color);
}

.pageTitle {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 3rem;
}

.pageSubTitle {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 3rem;
}

@import 'colors.css';

.viewitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: var(--border-color);
  background-color: var(--background-color);
  padding: 0.5rem;
  margin: 0.5rem;
}
.viewitem.full {
  flex: 0 1 30%;
}
.viewitem.middle {
  width: 220px;
}
.viewitem.compact {
  width: 86px;
}
.viewitem.draggable {
  cursor: move;
}
.viewitem.selected {
  box-shadow: 0 0 2px 2px var(--highlight-color) inset;
}

.viewbox {
  width: 100%;
  padding: 1rem 1rem;
  background-color: var(--secondary-background-color);
  border-color: var(--border-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}

.xscroll {
  display: flex;
  overflow-x: scroll;
}
.xscroll::-webkit-scrollbar {
  display: none;
}

.maxHeight600 {
  display: flex;
  max-height: 600px;
  overflow-y: scroll;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

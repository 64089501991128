* {
  box-sizing: border-box;
}

.imgCompBox {
  position: relative;
}

.imgCompImage {
  position: absolute;
  overflow: hidden;
}

.imgCompImage img {
  display: block;
}

.imgCompSlider {
  position: absolute;
  z-index: 9;
  cursor: ew-resize;
  /*set the appearance of the slider:*/
  width: 20px;
  opacity: 0.7;
  border-radius: 50%;
}

.imgLabel {
  position: absolute;
  top: 6px;
  font-size: small;
  color: white;
  text-shadow: -1px 0px black, 0px 1px black, 1px 0px black, 0px -1px black;
  display: flex;
  overflow: hidden;
}

:root {
  --highlight-color: #ff7f00;
  --highlight-hover-color: rgba(255, 127, 0, 0.8);
  --highlight-color-blue: #40a7ff;
  --background-color: #ffffff;
  --secondary-background-color: #f5f5f5;
  --thumbnailbox-background-color: #fafafa;
  --border-color: #e8e8e8;
  --icontext-default-color: #5c5c5c;
  --titletext-color: #000000;
  --template-badge-color: #00ff00;
  --sticker-badge-color: #ff1493;
  --font-badge-color: #0000ff;
  --layout-badge-color: #ffa500;
  --background-badge-color: #00bfff;
  --placeholder-badge-color: #cd87ff;
  --color-badge-color: #d45934;
  --combo-badge-color: #266f26;
  --text-badge-color: #26282e;
  --shape-badge-color: #7d1f92;
  --gradient-badge-color: #b81818;
  --pack-badge-color: #7a23ec;
  --banner-badge-color: #15bc66;
  --placeholder-fill-color: #e9f6ff;
  --route-badge-color: #e88d42;
  --creator-badge-color: #190d77;
  --warning-text-color: #f10707;
}

@import 'colors.css';

.hinttextOrange {
  padding-top: 5px;
  font-size: 80%;
  color: var(--highlight-color);
}

.warningtextRed {
  padding-top: 5px;
  font-size: 80%;
  color: var(--warning-text-color);
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: left;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.flexItemRightMargin {
  margin-right: 100px;
}

.buttonOrange {
  border: 0;
  color: white;
  background-color: var(--highlight-color);
}
.buttonOrange:hover,
.buttonOrange:focus {
  border: 0;
  color: white;
  background-color: var(--highlight-hover-color);
}

.rowBottomMargin {
  margin-bottom: 1rem;
}

.selectedRadioButtonText {
  color: #1890ff;
}

.boldText {
  font-weight: bold;
}

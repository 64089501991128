.box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
  border-radius: 5px;
  background-color: var(--thumbnailbox-background-color);
}

.image {
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
  background-color: var(--background-color);
}
